import React from "react";
import { PaymentPageComponent } from "tuteria-frontend-components/src/pages/TutorGroupPages/PaymentPageComponent";
let PaystackPublickeyFunc = x =>
  process.env.NODE_ENV === "development"
    ? "pk_test_3146e297e6d0463fea560139bc122a4aae04fedb"
    : x;
export class LessonSchedulePage extends React.Component {
  constructor(props) {
    super(props);
    let {
      payment_details = {},
      request_details = { request_details: {} }
    } = this.props.state;
    this.state = {
      payment_details: payment_details,
      selectedDetails: request_details.request_details
    };
  }
  hasSlug() {
    let { state } = this.props;
    let result =
      Boolean(state.request_details) && Boolean(state.request_details.slug);
    return result;
  }
  isCompleted() {
    const { state: { status } = {} } = this.props;
    return status === "completed";
  }
  componentDidMount() {
    const {
      params: { slug }
    } = this.props.match;
    this.props
      .getGroupLessonInfo(slug)
      .then(data => {
        let { payment_details, request_details } = data;
        this.setState({
          payment_details,
          selectedDetails: request_details.request_details
        });
      })
      .catch(err => {});
  }
  onCallback = ({ hourFactor, request_dump, planFactor = {} }) => {
    let { request_details } = this.state;
    request_details = {
      ...request_details,
      ...request_dump.request_details
    };
    this.setState({
      request_details,
      hourFactor,
      planFactor
    });
  };

  onSubmit = (url, callback) => {
    let {
      match: {
        params: { slug }
      },
      navigate,
      updateGroupLessonPayment
    } = this.props;
    updateGroupLessonPayment(
      url,
      data => {
        navigate(`/request-completed/${slug}`);
      },
      err => {
        navigate(`/request-completed/${slug}`);
        console.log(err);
      }
    );
  };
  goStepBack = () => {
    this.props.history.goBack();
  };
  paymentCancelled = () => {};
  render() {
    return (
      <React.Fragment>
        <PaymentPageComponent
          paymentCallbackSuccess={this.onSubmit}
          paymentCallbackCancel={this.paymentCancelled}
          request_details={this.state.selectedDetails}
          onSubmit={this.onSubmit}
          payment_details={this.state.payment_details}
          public_key={PaystackPublickeyFunc}
        />
      </React.Fragment>
    );
  }
}
