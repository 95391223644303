import React from "react";
import { PersonalInfoPage } from "tuteria-frontend-components/src/pages/TutorGroupPages/PersonalInfoPage";
import isEmail from "validator/lib/isEmail";
import isMobile from "validator/lib/isMobilePhone";
import { generateLessonInfo } from "tuteria-frontend-components/src/pages/ExternalPages/GroupLandingPage/utils";

const personalInfoValidation = (fields = {}) => {
  if (!!fields) {
    let stringNotEmpty = [
      "first_name",
      "last_name",
      "email",
      "gender",
      "phone_number"
    ]
      .map(x => Boolean)
      .every(Boolean);
    let validPhoneNumber = isMobile(fields.phone_number || "", "en-NG");
    let validEmail = isEmail(fields.email || "");
    return stringNotEmpty && validPhoneNumber && validEmail;
  }
  return false;
};
function profileData(props) {
  return {
    // loading: props.loading,
    defaultValidation: false,
    errors: {
      first_name: ["This field is required"],
      last_name: ["Your last name is required"],
      email: ["A valid email is required"],
      gender: ["You forgot to place a gender"],
      country: ["Please this field is required"],
      phone_number: ["Please enter a valid phone number"],
      phone_combined: ["Please enter a valid phone number"],
      combined: "Your first name and last name is invalid"
    },
    customValidation: personalInfoValidation,
    whereYouHeardOptions: [
      "Facebook",
      "Search Engine (Google/Yahoo/Bing)",
      "Friends, Family or Word of Mouth",
      "Instagram",
      "LinkedIn",
      "Saw a Flyer",
      "At an Event",
      "Spoke with an Agent",
      "YouTube",
      "Got an SMS",
      "Twitter",
      "Heard on Radio",
      "Watched on TV",
      "Nairaland",
      "Read a Blog Post",
      "Others"
    ].map((x, i) => ({ text: x, value: i.toString() })),
    validateField: (state, field, err) => {
      if (field === "combined") {
        return !!state.first_name && !!state.last_name;
      }
      if (field === "phone_number") {
        return (
          Boolean(state.phone_number) && isMobile(state.phone_number, "en-NG")
        );
      }
      if (field === "email") {
        return Boolean(state.email) && isEmail(state.email);
      }
      if (field === "phone_combined") {
        return (
          Boolean(state.phone_number) && isMobile(state.phone_number, "en-NG")
        );
      }
      if (["first_name", "last_name", "email"].includes(field)) {
        return Boolean(state[field]);
      }
      return true;
    }
  };
}

export class ContactInfoPage extends React.Component {
  constructor(props) {
    super(props);
    let { location = {}, personal_info = {} } = this.props.state;
    this.state = {
      location,
      personal_info,
      loading: false
    };
  }
  componentDidMount() {
    let { selectedDetails = {}, request_details = {} } = this.props.state;
    if (typeof window !== "undefined") {
      let item = window.sessionStorage.getItem("selectedDetails");
      if (item !== null) {
        selectedDetails =
          Object.keys(selectedDetails).length > 0
            ? selectedDetails
            : JSON.parse(item);
      }
    }
    if (
      Object.keys(selectedDetails).length === 0 ||
      (request_details && request_details.request_details)
    ) {
      this.props.navigate("");
    }
    // this.props.updateState({ currentStep: 2 });
    // let { navigate } = this.props;
    // if (Object.keys(this.state.location).length === 0) {
    //   navigate("/location");
    // }
  }
  formatPhoneNumber = val => {
    //the problem with this implementation is that we have used this
    // before in the tutor application flow and it would have been just
    //easier to go down there and take it.

    if (val[0] === "0") {
      return "234".concat(val.substring(1));
    }
    return "234".concat(val);
  };
  transformNumber(number, code = "234") {
    if (number.startsWith("0")) {
      return number.replace("0", code);
    }
    return code.concat(number);
  }
  onSubmit = personal_info => {
    let {
      state: { selectedDetails, request_details },
      initializeGroupLessons,
      navigate,
      exam
    } = this.props;
    this.setState({ loading: true });
    let lesson_info = {};
    if (request_details && request_details.request_details) {
      lesson_info = request_details.request_details;
    } else {
      lesson_info = generateLessonInfo(selectedDetails);
    }
    initializeGroupLessons(
      { ...lesson_info, exam },
      {
        ...personal_info,
        phone_number: this.transformNumber(personal_info.phone_number)
      },
      data => {
        navigate(`/lesson-schedule/${data}`);
      },
      error => {
        this.setState({ loading: false });
      }
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <PersonalInfoPage
        onSubmit={this.onSubmit}
        loading={this.state.loading}
        {...profileData(this.props)}
        data={this.state.personal_info}
        showBackButton={true}
        backAction={this.goBack}
      />
    );
  }
}
