import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { injectGlobal } from "styled-components";
import { ContactInfoPage } from "./ContactInfoPage";
import { LessonSchedulePage } from "./LessonSchedulePage";
import ClientThankYouPage from "./ClientThankYouPage";
import { color } from "tuteria-frontend-components/src/siteStyle";
import { NavBar } from "tuteria-frontend-components/src/compound/Navigation";

injectGlobal`
body {
  background: ${color.white}!important;
}
#root {
  overflow-x: hidden;
}
`;
export class ExamFlow extends React.Component {
  navigate = path => {
    let { basePath = "" } = this.props;
    if (path === "") {
      let v = this.props.basePath.split("/info")[0];
      this.props.history.push(v);
    } else {
      this.props.history.push(`${basePath}${path}`);
    }
  };
  componentDidMount() {
    // let { selectedDetails } = this.props.state.state;
    // if (Object.keys(selectedDetails).length === 0) {
    //   let v = this.props.basePath.split("/info")[0];
    //   this.props.history.push(v);
    // }
  }
  render() {
    // let { selectedDetails } = this.props.state.state;
    let { basePath = "" } = this.props;
    return (
      <React.Fragment>
        <Switch>
          <Route
            path={`${basePath}/contact-information`}
            render={props => (
              <React.Fragment>
                <NavBar />
                <ContactInfoPage
                  {...props}
                  exam={this.props.exam}
                  {...this.props.state}
                  navigate={this.navigate}
                />
              </React.Fragment>
            )}
          />
          <Route
            path={`${basePath}/lesson-schedule/:slug`}
            render={props => {
              return (
                <React.Fragment>
                  <NavBar />
                  <LessonSchedulePage
                    {...props}
                    {...this.props.state}
                    exam={this.props.exam}
                    navigate={this.navigate}
                  />
                </React.Fragment>
              );
            }}
          />
          <Route
            path={`${basePath}/request-completed/:slug`}
            render={props => (
              <ClientThankYouPage
                {...props}
                {...this.props.state}
                exam={this.props.exam}
                navigate={this.navigate}
              />
            )}
          />
          <Redirect
            from={`${basePath}/`}
            to={`${basePath}/contact-information`}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default ExamFlow;
