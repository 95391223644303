import React, { Component } from "react";
import { ClientThankYou } from "tuteria-frontend-components/src/pages/ExamRequestPages";

const URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://www.tuteria.com";

export default class ClientThankYouPage extends Component {
  render() {
    const { agent, paid_fee = false, fee_link } = this.props.state;
    return (
      <React.Fragment>
        <ClientThankYou
          bitmap="/static/img/Thank-You.png"
          link={paid_fee ? "" : `${URL}${fee_link}`}
          {...agent}
        />
      </React.Fragment>
    );
  }
}
